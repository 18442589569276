import { io } from "socket.io-client";

const URL =
  process.env.NODE_ENV === "production"
    ? "https://hlvi.co.kr/gameSession"
    : // "https://socialvalueinvestor.com/gameSession"
      "http://localhost:5001/gameSession";
// : 'http://121.138.109.188:5001/gameSession';

export const gameSocket = io(URL, {
  transports: ["websocket"],
  autoConnect: false,
});
